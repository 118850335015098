.ContactForm {
    font-size: 14px;
    text-align: center;
    width: 320px;
    margin: auto;
    color: #ffffff;
}

.ContactForm-form {
    text-align: left;
}

.ContactForm-input {
    display: block;
    text-align: center;
    width: 100%;
    box-sizing: border-box;
    line-height: 36px;
    border-radius: 2px;
    border: none;
    font-size: 18px;
    margin-bottom: 1rem;
}

.ContactForm-msg {
    display: block;
    padding: 0.5rem;
    width: 100%;
    box-sizing: border-box;
    border-radius: 2px;
    border: none;
    font-size: 18px;
    margin-bottom: 1rem;
}

.ContactForm-button {
    box-sizing: border-box;
    width: 100%;
    background-color: transparent;
    border: 2px solid #53BA5D;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 16px;
    line-height: 36px;
    color: #53BA5D;
    white-space: nowrap;
}

.ContactForm-button:hover{
    background-color: #53BA5D09;
}

.ContactForm-context {
    font-size: 1rem;
    vertical-align: middle;
    margin: 2rem 0;
}

.ContactForm-success {
    display: block;
    font-size: 1rem;
    margin: 2rem 0;
}