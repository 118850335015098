.IntroPage {
    color: #cccccc;
    text-align: left;
    font-size: 18px;
}

.IntroPage h1 {
    color: #efefef;
    
}

.IntroPage strong {
    color: #eeeeee;
}