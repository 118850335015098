.AboutPage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    justify-content: space-between;
    color: #B4B4B4;
}

.AboutPage-subtitle{
    margin-top: 0;
}

.AboutPage-contact {
    text-align: left;
}

.AboutPage-graph {
    padding: 1rem 0;
    text-align: center;
}

.AboutPage-form {
    padding-bottom: 10vh;
}