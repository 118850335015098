.SubscribeForm {
    font-size: 14px;
    text-align: center;
    color: #ffffff;
}

.SubscribeForm-form {
    display: flex;
}

.SubscribeForm-email {
    flex-grow: 1;
    text-align: center;
    line-height: 36px;
    border-radius: 2px;
    border: none;
    font-size: 18px;
    margin-right: 1rem;
}

.SubscribeForm-button {
    box-sizing: border-box;
    background-color: transparent;
    border: 2px solid #53BA5D;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 16px;
    line-height: 21px;
    color: #53BA5D;
    white-space: nowrap;
}


.SubscribeForm-button:hover{
    background-color: #53BA5D09;
}


.SubscribeForm-context {
    font-size: 1rem;
    vertical-align: middle;
}

.SubscribeForm-success {
    display: block;
    font-size: 1rem;
    margin: 2rem 0;
}

.SubscribeForm-success > svg {
    margin-left: 1rem;
    vertical-align: middle;
}