.App {
    margin: auto;
    position: relative;
    text-align: center;
    max-width: 420px;
    max-width: 640px;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App a,
.App a:visited,
.App a:link {
    color: white;
}

.App a:hover {
    color: #9AB8C9;
}

.App-header {
    position: absolute;
    right: 0;
    top: 0.5rem;
    text-align: right;
    font-size: 16px;
}

.App-header > a {
    margin-right: 1rem;
}

.App-header > a.selected {
    text-decoration: none;
}

.App-body {
    display: flex;
    min-height: 100vh;
    padding: 0 1rem;
}

.App-content {
    margin-top: 10vh;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}