.HomePage {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.HomePage-logo {
}

.HomePage-subtitle {
    color: #B4B4B4;
}

.HomePage-cta {
    color: #B4B4B4;
    padding-bottom: 1rem;
    font-size: 14px;
}

.HomePage-form {
    padding-bottom: 10vh;
    min-width: 20rem;
    max-width: 75vw;
}